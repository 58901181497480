/*------------------------------------------------------------------------------------------------------------
-----------------------------------------------Main-----------------------------------------------------------
------------------------------------------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');




#language{
    position: absolute;
    border: none;
    top: 2vh;
    right: 10vw;
    animation-name: language-appear;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    opacity: 0;
}

#pcp-language{
    position: absolute;
    border: none;
    margin: 0;
    top: 1vh;
    right: 1vw;
    animation-name: language-appear;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    opacity: 0;
}

@keyframes language-appear{
    from{opacity: 0;}
    to{opacity: 1;}
  }

/****************************************File Browser********************************************************/

.app-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.browse-wrapper{
    position: relative;
    width: 70vw;
    margin-left: 5vw;
    margin-top: 5vh;
    min-height: 100vh;
}

.pcp-browse-wrapper{
    position: relative;
    width: 90vw;
    margin-left: 5vw;
    margin-top: 2.5vh;
    min-height: 100vh;
}

.btn-wrapper{
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.display-wrapper{
    margin: 0;
    padding: 0;
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.788);
}

.doc-wrapper{
    margin: 0;
    margin-left: 15.5vw;
    padding: 0;
    height: 100vh;
    width: 71vw;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.pcp-doc-wrapper{
    margin: 0;
    padding: 0;
    margin-top: 9vh;
    height: 80vh;
    width: 100vw;
    position: absolute;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
}

.doc{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: white;
}

.tabl-doc{
    position: relative;
    display: flex;
    justify-content: center;

    flex-direction: row;
    color: white;
    height: 100vh;
}

#loading{
    position: relative;
   color: rgb(182, 39, 39);
   margin-top: 30vh;
   margin-left: 23vw;
}

@keyframes loading-anim{
    from{transform: scaleX(0);}
    to{transform: scaleX(1);}
}

#loading::before{
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(182, 39, 39);
    transform: scaleX(0);
    transform-origin: left;
    animation-name: loading-anim;
    animation-fill-mode: forwards;
    animation-duration: 4s;
    animation-delay: 0.5s;
}



.double-pdf{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.pcp-double-pdf{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

#btn-container-left{
    position: absolute;
    top: 15vh;
    height: 70vh;
    pointer-events: none;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 4.5vw;
    left: 5vw;
}

#pcp-btn-container-left{
    position: absolute;
    top: 3vh;
    height: 4vh;
    pointer-events: none;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
}

#btn-container-right{
    position: absolute;
    top: 15vh;
    right: 5vw;
    height: 70vh;
    width: 4.5vw;
    pointer-events: none;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#pcp-btn-container-right{
    position: absolute;
    bottom: 5vh;
    height: 4vh;
    pointer-events: none;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
}

.doc-nav-button{
    background-color: white;
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    border: solid black 2px;
}

.pcp-doc-nav-button{
    background-color: white;
    height: 100%;
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    border: solid black 2px;
}

#close{
    position: absolute;
    top: 2vh;
    right: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    outline: none;
}

#pcp-close{
    position: absolute;
    top: 0.5vh;
    right: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    outline: none;
}

#close-img{
    margin: 0;
    padding: 0;
}

.download-link{
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-button, .unzoom, .zoom, .next-btn, .previous-btn{
    pointer-events: all;
    outline: none;
  }

.download-button:focus{
    outline: none;
}
  
  #page-counter{
    position: absolute;
    width: fit-content;
    left: 5vw;
    text-align: center;
    bottom: 1vh;
    color: white;
  }

#center-bg{
    opacity: 0.8;
}

button{    
    font-size: calc(0.4vh + 0.8vw);
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'Courgette', cursive;
    background: none;
    color: black;
    border-radius: 10%;
    
}

.link{
    margin: 1vh 1vw 1vh 1vw;
    height: fit-content;
    background-color: rgb(226, 226, 226);
    border: none;
    border-radius: 10%;
    opacity: 0.7;
    transition: opacity 0.3s ease-out, background-color 0.3s ease-out;
}

.link:hover{
    opacity: 1;
    background-color: rgba(233, 233, 233, 0.932);
}

.link:focus{
    outline: none;
}

.file-link{
    /* margin: 2vh 2vw 2vh 2vw; */
    border-bottom-right-radius: 10% 30%;
}

.folder-btn, .back-btn{
    height: 10vh;
    width: 8vw;
    border: none;
    border-top: 1px solid rgb(255, 255, 255);
    border-left: 1px solid rgb(255, 255, 255);
    border-right: 1px solid rgb(97, 97, 97);
    border-bottom: 1px solid rgb(97, 97, 97);
    text-transform: capitalize;
}

.pcp-folder-btn, .pcp-back-btn{
    height: 10vh;
    width: 16vw;
    border: none;
    border-top: 1px solid rgb(255, 255, 255);
    border-left: 1px solid rgb(255, 255, 255);
    border-right: 1px solid rgb(97, 97, 97);
    border-bottom: 1px solid rgb(97, 97, 97);
    text-transform: capitalize;
}

.back-link{
    margin-bottom: 4vh;
    background-color: transparent;
}

.back-btn, .pcp-back-btn{
    width: fit-content;
    height: fit-content;
    background-color: rgb(226, 226, 226);

}

.file-btn{
    position: relative;
    height: 30vh;
    width: 12vw;
    border: 1px solid black;
    border-bottom-right-radius: 10% 30%;
    text-transform: capitalize;
    background-color: rgb(252, 250, 246);
    transition: font-size 0.3s ease-out, background-color 0.3s ease-out;
}

.pcp-file-btn{
    position: relative;
    height: 15vh;
    width: 20vw;
    border: 1px solid black;
    border-bottom-right-radius: 10% 30%;
    text-transform: capitalize;
    background-color: rgb(252, 250, 246);
    transition: font-size 0.3s ease-out, background-color 0.3s ease-out;
}

.folder-btn:focus, .pcp-folder-btn:focus, .back-btn:focus, .pcp-back-btn:focus, .file-btn:focus, .pcp-file-btn:focus{
    outline: none;
}

.file-btn::before, .pcp-file-btn::before{
    content: '';
    position: absolute;
    height: 80%;
    width: 80%;
    background: url('./sitebackground.png');
    background-size: 100%;
    top: 10%;
    left: 10%;
    opacity: 0.1;
    transition: opacity 0.3s ease-out;
}

.file-btn:hover{
    font-size: calc(0.5vh + 1vw);
    background-color: rgb(255, 253, 250);
}

.file-btn:hover::before{ 
    opacity: 0.3;
}

.btns-appear{
    transform: scale(0);
  }
  
.btns-appear-active{
    transition: transform 0.3s ease-out;
    transform-origin: center;
    transform: scale(1);
}

.btns-enter{
    transform: scale(0);
}

.btns-enter-active{
    transition: transform 0.3s ease-out;
    transform-origin: center;
    transform: scale(1);
}

.btns-exit{
    transform: scale(1);
}

.btns-exit-active{
    transition: transform 0.3s ease-out;
    transform-origin: center;
    transform: scale(0);
}

/*------------------------------------------------------------------------------------------------------------
----------------------------------------Pc Landscape-(pcl)----------------------------------------------------
------------------------------------------------------------------------------------------------------------*/
/*
#appear-later{
    animation-name: appear-later;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    display: none;
}

@keyframes appear-later{
    from{display: none;}
    to{display: block;}
}*/

#svg-anim{
    position: absolute;
    z-index: 5;
}

.title-appear{
    transform: translateX(-100vw);
    opacity: 0;
}

.title-appear-active{
    transform: translateX(0);
    opacity: 1;
    transition: transform 2s ease-out, opacity 2s ease-out;
}

.title-appear-done{
    transform: translateX(0);
    opacity: 1;
}

.sub-title-appear{
    transform: translateX(100vw);
    color: black;
    opacity: 0;  
}

.nav-appear{
    opacity: 0;
}

.nav-appear-active{
    opacity: 1;
    transition: opacity 2s ease-out;
    transition-property: opacity;
    transition-delay: 2s;
}

.nav-appear-done{
    opacity: 1;
}

.sub-title-appear::before{
    transform: scaleX(0);  
}

.sub-title-appear-active::before{
    transform-origin: left;
    transform: scaleX(1);
    transition: transform 2s;
    transition-timing-function: cubic-bezier(0,.63,1,-0.28);
    transition-property: transform;
    transition-delay: 2s;
}

.sub-title-appear-active{   
    transform: translateX(0);
    color: white;    
    opacity: 1;
    transition: transform 2s ease-out, color 2s ease-out, opacity 2s ease-out;
    transition-property: transform, color, opacity;
    transition-delay: 0s;
    
    /* transition: color 2s ease-out;
    transition-property: transform;
    transition-delay: 2s; */
}

.sub-title-appear-done{   
    transform: translateX(0);
    color: white;    
    opacity: 1;
}

/* .sub-title-enter{
    transform: translateX(100vw);
    color: white;
    opacity: 0;  
}

.sub-title-enter-active{   
    transform: translateX(0);
    color: black;    
    opacity: 0.3;
    transition: transform 2s ease-out, color 2s ease-out, opacity 2s ease-out;
    transition-property: transform, color, opacity;
    transition-delay: 2s; */
    
    /* transition: color 2s ease-out;
    transition-property: transform;
    transition-delay: 2s; 
}*/

.pcl-content-wrapper {
    margin: 0;
    padding: 0;
    width: 80vw;
    position: relative;
    left: 10vw;
    font-family: 'Courgette', cursive;
}

.pcl-background-container-global{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 120vw;
    overflow: hidden;
    position: fixed;
    z-index: -2;
    background-color: rgb(255, 255, 255);
}

.pcl-background-container {
    top: 0;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 80vw;
    overflow: hidden;
    position: fixed;
    z-index: -1;
    box-sizing: border-box;
    background-color: white;
    border-right: 2px solid black;
    border-left: 2px solid black;
    display: flex;
    justify-content: center;
}

#pcl-piano-jpg{
    position: absolute;
}

#black-bg{
    position: absolute;
    background-color: rgb(0, 0, 0);
    height: 60vh;
    width: 100vw;
    top: 1020px;
    z-index: -5;
}

.pcl-header {
    position: relative;
    margin: 0;
    background-color: transparent;
    text-align: center;
    border-bottom-left-radius: 50% 30%;
    margin-top: 10vh;
}

.pcl-header-scores{
    position: relative;
    margin: 0;
    background-color: transparent;
    text-align: center;
    border-bottom-left-radius: 50% 30%;
    margin-top: 5vh;
}

.pcl-header > h1, ul, li, .pcl-header-scores > h1, ul, li{
    color: white;
    text-shadow: 1px 3px 3px black;
}

.pcl-nav{
    position: relative;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 1.5vh;
    width: 45vw;
    margin-left: 15vw;
    font-size: calc(0.5vh + 1vw);
}

.pcl-nav-list{
    color: white;
    text-decoration: none;
    text-shadow: 2px 2px 6px black;
}

.pcl-nav-list-container{
    position: relative;
}

.pcl-nav-list-container::before{
    content: '';
    position: absolute;
    background-color: rgb(182, 39, 39);
    left: 5%;
    bottom: -2px;
    height: 2px;
    width: 90%;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-out;
}
/* try to select the a via the container  like > and > etc then apply ::after for example*/
.pcl-nav-list-container:hover::before{    
    transform: scaleX(1);
    transform-origin: left;
}

a:focus {
    border-bottom: rgb(182, 39, 39) solid 2px;
    outline: none;
}


#pcl-main-title {
    position: relative;
    margin: 0;
    padding-top: 3vh;
    padding-bottom: 2vh;
    font-size: calc(2vw + 1vh);
}



.pcl-h2 {
    margin-bottom: 0;
    padding-bottom: 0;
}

.pcl-main{
    position: relative;
    padding-left: 10vw;
    padding-right: 10vw;
}

.pcl-main-about{
    margin-top: 10vh;
    position: relative;
    padding-left: 10vw;
    padding-right: 10vw;
}

.score-btns{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
    margin-bottom: 10vh;
}

.pcp-score-btns{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    margin-left: 5vw;
    margin-bottom: 5vh;
}

.score-btns-appear{
    transform: scaleX(0);
    transform-origin: left;
}

.score-btns-appear-active{
    transform: scaleX(1);
    transition: transform 0.5s ease-out;
}

.score-btns-appear-done{
    transform: scaleX(1);
}

.score-display-btn{
    border: solid 2px black;
    background-color: rgba(47, 66, 77, 0.548);
    outline: none;
    transition: background-color 0.3s ease-out;
}

.score-display-btn:hover{
    background-color: rgba(49, 49, 49, 0.699);
}

#filler{
    position: relative;
    height: 50vh;
}

#pcp-filler{
    position: relative;
    height: 70vh;
}

.main-about-text{
    width: 40vw;
    margin-left: 10vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    border-radius: 25%;
    background-color: rgba(47, 66, 77, 0.767);
}

.pcp-main-about-text{
    width: 80vw;
    height: fit-content;
    margin-left: 10vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    border-radius: 25%;
    background-color: rgba(47, 66, 77, 0.767);
}

.about-tariffs-anim-appear{
    opacity: 0;
}

.about-tariffs-anim-appear-active{
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.about-tariffs-anim-appear-done{
    opacity: 1;
}

.about-tariffs-anim-enter{
    opacity: 0;
}

.about-tariffs-anim-enter-active{
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.about-tariffs-anim-enter-done{
    opacity: 1;
}

.about-tariffs-anim-exit{
    opacity: 1;
}

.about-tariffs-anim-exit-active{
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.about-tariffs-anim-exit-done{
    opacity: 0;
}

.about-text-anim-enter{
    opacity: 0;
}

.about-text-anim-enter-active{
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.about-text-anim-enter-done{
    opacity: 1;
}

.about-text-anim-exit{
    opacity: 1;
}

.about-text-anim-exit-active{
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.about-text-anim-exit-done{
    opacity: 0;
}

#pcl-subtitle-container{
    position: relative;
    height: 60vh;
    margin-top: 8vh;
    margin-bottom: 7vh;
    left: 18vw;
    width: fit-content;
}

#pcl-subtitle{
    position: sticky;
    font-size: calc(1.5vw + 0.75vh);
    top: 25vh;
    margin: 0;
    padding: 0;
    text-align: right;
    /*text-decoration: underline 2px solid rgb(182, 39, 39);*/
    line-height: 0;
    cursor: pointer;
}

#pcl-subtitle::before{
    content: '';
    position: absolute;
    left: 5%;
    width: 90%;
    height: 2px;
    bottom: -20px;
    background-color: rgb(182, 39, 39);
}

.pcl-main-text{
    position: relative;
    font-size: calc(0.5vh + 1vw);
    line-height: 6vh;
    margin-top: 3vh;
    margin-bottom: 8vh;
    text-shadow: 1px 1px 0px white;
}

.pcl-footer{
    margin-top: 5vh;
    min-height: 8vh;
    color: white;
    position: relative;
    background-color: black;
    border-top-right-radius: 50% 30%;
}

.pcl-footer-about{
    margin-top: 5vh;
    min-height: 8vh;
    color: white;
    position: relative;
    background-color: black;
    border-top-right-radius: 50% 30%;
}

#pcl-footer-text{
    margin-left: 5vw;
    padding-top: 3vh;
}

#pcl-footer-text > a:link, #pcl-footer-text > a:visited, #pcl-footer-text > a:hover, #pcl-footer-text > a:active{
    text-decoration: none;
    color: white;
}

.pcp-footer-about{
    margin-top: 5vh;
    min-height: 8vh;
    color: white;
    position: relative;
    background-color: black;
    border-top-right-radius: 50% 30%;
}

#pcp-footer-text{
    margin-left: 5vw;
    padding-top: 3vh;
}

#pcp-footer-text > a:link, #pcp-footer-text > a:visited, #pcp-footer-text > a:hover, #pcp-footer-text > a:active{
    text-decoration: none;
    color: white;
}

.tariffs-wrapper > p, ul > li{
    color: white;
    list-style: none;
    font-size: calc(0.5vw + 1vh);
    text-align: center;
}

.tariffs-wrapper-phone-landscape > p, .tariffs-wrapper-phone-landscape > ul > li{
    font-size: calc(0.75vw + 1.5vh);

}

.catalog > p, .license > p, .contact > p, .pcp-contact > p{
    color: white;
    font-size: calc(0.625vw + 1.25vh);
    text-align: center;
}

.license > p{
    margin-left: 3vw;
    margin-right: 3vw;
    font-size: calc(0.5vw + 1vh);
}

.contact > p > a:link, .pcp-contact > p > a:link{
    text-decoration: none;
    color: white;
}

.contact{
    margin-top: 12vh;
    margin-bottom: 12vh;
}

.pcp-contact{
    margin-top: 10vh;
}

.contact > p, .pcp-contact > p{
    margin-left: 3vw;
    margin-right: 3vw;
}

.pcl-score-section{
    color: white;
}

.tariffs-wrapper > ul, .catalog > ul, .license > ul{
    padding-left: 0;
}

@media(max-width: 1040px){
    .tariffs-wrapper > p, ul > li, .catalog > p, .license > p, .contact > p, .pcp-contact > p{
        font-size: calc(1vw + 1vh);
    }
}

@media(max-height: 1040px){
    .tariffs-wrapper > p, ul > li, .catalog > p, .license > p, .contact > p, .pcp-contact > p{
        font-size: calc(1vw + 1vh);
    }
}
/*------------------------------------------------------------------------------------------------------------
----------------------------------------Pc Portrait-(pcp)-----------------------------------------------------
------------------------------------------------------------------------------------------------------------*/
.pcp-content-wrapper {
    margin: 0;
    padding: 0;
    width: 100vw;
    min-height: 70vh;
    position: relative;
    font-family: 'Courgette', cursive;
}

.pcp-background-container-global{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 120vw;
    overflow: hidden;
    position: fixed;
    z-index: -2;
    background-color: rgb(255, 255, 255);
    display: none;
}

.pcp-background-container {
    top: 0;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    z-index: -1;
    box-sizing: border-box;
    background-color: white;
    border-right: 2px solid black;
    border-left: 2px solid black;
    display: flex;
    justify-content: center;
}

#pcp-piano-jpg{
    position: absolute;
}

.pcp-header {
    position: relative;
    margin: 0;
    background-color: transparent;
    text-align: center;
    border-bottom-left-radius: 50% 30%;
    margin-top: 5vh;
}

.pcp-header-scores{
    position: relative;
    margin: 0;
    background-color: transparent;
    text-align: center;
    border-bottom-left-radius: 50% 30%;
    margin-top: 5vh;
}

.pcp-header > h1, ul, li, .pcp-header-scores > h1, ul, li{
    color: white;
    text-shadow: 1px 3px 3px black;
}

.pcp-nav{
    position: relative;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 1.5vh;
    width: 70vw;
    margin-left: 12vw;
    font-size: calc(0.5vh + 1vw);
}

.pcp-nav-list{
    color: white;
    text-decoration: none;
    text-shadow: 2px 2px 6px black;
}

.pcp-nav-list-container{
    position: relative;
}

.pcp-nav-list-container::before{
    content: '';
    position: absolute;
    background-color: rgb(182, 39, 39);
    left: 5%;
    bottom: -2px;
    height: 2px;
    width: 90%;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease-out;
}

.pcp-nav-list-container:hover::before{    
    transform: scaleX(1);
    transform-origin: left;
}

#pcp-main-title {
    position: relative;
    margin: 0;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: calc(2vw + 1vh);
}

.pcp-h2 {
    margin-bottom: 0;
    padding-bottom: 0;
}

.pcp-main{
    position: relative;
    padding-left: 10vw;
    padding-right: 10vw;
}

.pcp-main-about{
    position: relative;
    min-height: 80vh;
}


#pcp-subtitle-container{
    position: relative;
    height: 33vh;
    margin-top: 4vh;
    margin-bottom: 4vh;
    left: 24vw;
    width: fit-content;
}

#pcp-subtitle{
    position: sticky;
    font-size: calc(1.5vw + 0.75vh);
    top: 13vh;
    margin: 0;
    padding: 0;
    text-align: right;
    line-height: 0;
    cursor: pointer;
}

#pcp-subtitle::before{
    content: '';
    position: absolute;
    left: 5%;
    width: 90%;
    height: 2px;
    bottom: -20px;
    background-color: rgb(182, 39, 39);
}

.pcp-main-text{
    position: relative;
    font-size: calc(0.5vh + 1vw);
    line-height: 3vh;
    margin-top: 1.5vh;
    margin-bottom: 4vh;
    text-shadow: 1px 1px 0px white;
}

.pcp-footer{
    margin-top: 2.5vh;
    min-height: 4vh;
    color: white;
    position: relative;
    background-color: black;
    border-top-right-radius: 50% 30%;
}

.pcp-footer-about{
    margin-top: 2.5vh;
    min-height: 4vh;
    color: white;
    position: relative;
    background-color: black;
    border-top-right-radius: 50% 30%;
}

#pcp-footer-text{
    margin-left: 5vw;
    padding-top: 1.5vh;
}

#pcp-footer-text > a:link, #pcp-footer-text > a:visited, #pcp-footer-text > a:hover, #pcp-footer-text > a:active{
    text-decoration: none;
    color: white;
}

.pcp-score-section{
    color: white;
}


/*------------------------------------------------------------------------------------------------------------
----------------------------------------Tablet Landscape-(tabl)-----------------------------------------------
------------------------------------------------------------------------------------------------------------*/



/*------------------------------------------------------------------------------------------------------------
----------------------------------------Tablet Portrait-(tabp)------------------------------------------------
------------------------------------------------------------------------------------------------------------*/



/*------------------------------------------------------------------------------------------------------------
----------------------------------------Phone Landscape-(phol)------------------------------------------------
------------------------------------------------------------------------------------------------------------*/



/*------------------------------------------------------------------------------------------------------------
----------------------------------------Phone Portrait-(phop)-------------------------------------------------
------------------------------------------------------------------------------------------------------------*/